import { AfterViewInit, Component, Host, OnInit } from'@angular/core';
import { MatSelect } from '@angular/material';
import { AbstractEmbeddedMatOptionsComponent } from 'src/app/shared/abstract/abstract-embedded-mat-options.component';
import { InvoiceStatusValue } from 'src/app/shared/invoice-status/invoice-status-value';

interface InvoiceStatus {
    value: string,
    displayName: string
}

@Component({
  selector: 'app-invoice-status',
  templateUrl: 'invoice-status.component.html'
})
export class InvoiceStatusComponent extends AbstractEmbeddedMatOptionsComponent implements OnInit, AfterViewInit
{
  constructor(@Host() select: MatSelect) {
      super(select);
  }

  readonly invoiceStatusList: InvoiceStatus[] = [
    { value: InvoiceStatusValue.All, displayName: 'All'},
    { value: InvoiceStatusValue.NotReady, displayName: 'Not Ready'},
    { value: 'Ready', displayName: 'Ready'},
    { value: 'AdminHelp', displayName: 'Needs Admin Help'},
    { value: 'EmployeeInvoice', displayName: 'Employee Invoice'},
    { value: 'TGHReady', displayName: 'TGH Ready'},
    { value: 'ISG', displayName: 'ISG'},
    { value: 'PTR', displayName: 'PTR'},
  ];

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initOptions();
  }
}
