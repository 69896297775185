import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { OverviewReminder } from '../../../configuration-management/models/overview-reminder.interface';
import {Subscription} from 'rxjs';
import {PatientService} from '../../../patient-management/services/patient.service';

@Component({
  selector: 'app-patient-view-dialog',
  templateUrl: './patient-view.dialog.html',
  styleUrls: ['./patient-view.dialog.scss']
})
// tslint:disable-next-line:component-class-suffix
export class PatientViewDialog implements OnInit, OnDestroy {

  patient: Patient;
  subscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PatientViewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    public router: Router,
    public patientService: PatientService
  ) {}


  ngOnInit() {
    this.subscription = this.patientService.getPatientDialogue(this.data).subscribe(res => {
      this.patient = res;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  route() {
    this.router.navigateByUrl(`/patient-record/${this.data}`);
    this.dialogRef.close();
  }

  getOverview(): OverviewReminder[] {
    if (this.patient.overviewReminders) {
      return this.patient.overviewReminders.filter(x => x.showOnEQProtect === false);
    } else {
      return [];
    }
  }

  getEqProtect(): OverviewReminder[] {
    if (this.patient.overviewReminders) {
      return this.patient.overviewReminders.filter(x => x.showOnEQProtect === true);
    } else {
      return [];
    }

  }


  onNoClick() {
    this.dialogRef.close();
  }
}
