import {Component, Injector, Input, OnInit} from '@angular/core';
import {Branch} from '../../configuration-management/models/branch.interface';
import {BranchService} from '../../configuration-management/services/branch.service';
import {FormControl, FormGroupDirective} from '@angular/forms';
import {getNestedFormControl} from '../utils/form.util';

@Component({
  selector: 'app-select-branch',
  templateUrl: './select-branch.component.html',
  styleUrls: ['./select-branch.component.css']
})
export class SelectBranchComponent implements OnInit {

  branches: Branch[];
  formSearch: FormControl = new FormControl('');


  @Input() control: FormControl;
  @Input() controlName: string;
  @Input() label = 'Branch';
  @Input() allowAll = false;
  @Input() allowOther = false;

  private rootFormGroup: FormGroupDirective;

  constructor(private branchService: BranchService, private injector: Injector) { }

  ngOnInit() {
    if (this.controlName) {
      // Form group cannot be injected unless the component is inside a form
      this.rootFormGroup = this.injector.get(FormGroupDirective);
      this.control = getNestedFormControl(this.rootFormGroup.form, this.controlName);
    }
    this.branchService.index().subscribe(res => {
      this.branches = res.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

}
