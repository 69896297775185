import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LayoutService} from '../../../layout/services/layout.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InvoiceService} from '../../../line-item-entry/services/invoice.service';
import {
  EntityWithLineItemsReference,
  EntityWithLineItemsType,
  Invoice,
  InvoiceBase
} from '../../../line-item-entry/models/invoice.interface';
import {MatDialog} from '@angular/material/dialog';
import {PaymentDialogComponent} from './payment-dialog/payment.dialog';
import {AccountService} from '../../../configuration-management/services/account.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StatementService} from 'src/app/list-communications/services/statements.service';
import {ConfirmationDialogComponent} from 'src/app/shared/confirmation-dialog/confirmation.dialog';
import {ListCommunicationsService} from 'src/app/list-communications/services/list-communications.service';
import FileSaver from 'file-saver';
import {ItemLogDialogComponent} from 'src/app/shared/item-log-dialog/item-log.dialog';
import {LogPage} from 'src/app/configuration-management/models/logs.models';
import {InvoiceStatusValue} from 'src/app/shared/invoice-status/invoice-status-value';
import {EstimateService} from '../../../line-item-entry/services/estimate.service';
import {LineItemSubmission} from '../../../line-item-entry/models/line-item.interface';
import {storage} from '../../local-storage/local-storage.service';
import {LocalStorageNames} from '../../local-storage/local-storage.enum';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  @Input() invoiceData: EntityWithLineItemsReference;
  @Output() estimatePublished: EventEmitter<string> = new EventEmitter();
  keepRevision = false;
  public invoice: InvoiceBase = null;
  public reloadLineItemEntry = false;

  constructor(
    public layoutService: LayoutService,
    public accountService: AccountService,
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    public estimateService: EstimateService,
    public statementService: StatementService,
    public listCommunicationsService: ListCommunicationsService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router
  ) { }

  ngOnInit() {
    if (!this.invoiceData) {
      this.invoiceData = new EntityWithLineItemsReference();
      this.invoiceData.id = this.route.snapshot.paramMap.get('id');
      this.invoiceData.type = this.route.snapshot.url[0].path === 'estimate'
        ? EntityWithLineItemsType.Estimate : EntityWithLineItemsType.Invoice;
    }

    this.getInvoice();
  }

  getInvoice() {
    if (this.invoiceData.isEstimate) {
      this.estimateService.get(this.invoiceData.id).subscribe(
        (res) => this.invoiceReceived(res)
      );
    } else {
      this.invoiceService.getInvoice(this.invoiceData.id).subscribe(
        (res) => this.invoiceReceived(res)
      );
    }
  }

  setRoute() {
    this.layoutService.setRoute(`dashboard/`,
      `<span><span class="left-icon"></span> Dashboard</span>`);
  }

  invoiceReceived(response: InvoiceBase) {
    let count = 1;
    response.lineItems.forEach(x => {
      x.position = count;
      count++;
    });

    this.invoice = response;
    this.reloadLineItemEntry = false;
    this.setRoute();
  }

  payInvoiceDialog() {
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      width: '300px',
      data: {
        clientId: this.invoice.client.id,
        paymentId: null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getInvoice();
      }
    });
  }

  reload() {
    this.reloadLineItemEntry = false;
    this.ngOnInit();
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        header: `Delete ${this.getInvoiceOrEstimateText(false)}`,
        body: `This ${this.getInvoiceOrEstimateText(false)} will be deleted.`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // send
        if (this.invoiceData.isEstimate) {
          this.estimateService.delete(this.invoice.id).subscribe(x => {
            this.invoice = null;
            this.snackBar.open('Estimate was deleted.', 'Success', {
              duration: 2000,
            });
          });
        } else {
          this.invoiceService.deleteInvoice(this.invoice.id).subscribe(x => {
            this.invoice = null;
            this.snackBar.open('Invoice was deleted.', 'Success', {
              duration: 2000,
            });
          });
        }
      } else {
        // cancel
        this.snackBar.open('Operation cancelled.', '', {
          duration: 2000,
        });
      }
    });
  }

  send() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        header: `Send ${this.getInvoiceOrEstimateText(false)} Email`,
        body: `This ${this.getInvoiceOrEstimateText(false)} will be sent as an email to ${this.invoice.client.name}.`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // send
        if (this.invoiceData.isEstimate) {
          this.estimateService.sendEmail(this.invoice.id).subscribe(x => {
            this.snackBar.open('Estimate email sent.', 'Success', {
              duration: 2000,
            });
          });
        } else {
          this.statementService.sendInvoiceEmail(this.invoice.client.id, this.invoice.id).subscribe(x => {
            this.snackBar.open('Invoice email sent.', 'Success', {
              duration: 2000,
            });
          });
        }
      } else {
        // cancel
        this.snackBar.open('Operation cancelled.', '', {
          duration: 2000,
        });
      }
    });
  }

  print() {
    if (this.invoiceData.isEstimate) {
      this.listCommunicationsService.printEstimateEmail(this.invoice.id).subscribe(res => {
          FileSaver.saveAs(res, 'Estimate.pdf');
        },
        error => {
          this.snackBar.open('Unable to retrieve content.', 'Error', {
            duration: 2000
          });
        });
    } else {
      this.listCommunicationsService.printInvoiceEmail(this.invoice.id).subscribe(res => {
          FileSaver.saveAs(res, 'Invoice.pdf');
        },
        error => {
          this.snackBar.open('Unable to retrieve content.', 'Error', {
            duration: 2000
          });
        });
    }
  }

  publishEstimate() {
    const submission = new LineItemSubmission();
    submission.clientId = this.invoice.client.id;
    submission.patientId = this.invoice.patient.id;
    submission.lineItems = this.invoice.lineItems;

    this.estimateService.update(submission, this.invoice.id).subscribe(
      next => {
        this.estimateService.publish(submission, this.invoice.id).subscribe(
          newInvoiceId => {
            this.snackBar.open('Estimate published to invoice.', 'Success', {
              duration: 2000,
            });

            storage.setItem(LocalStorageNames.EstimatePublished, true);
            // If this component is embedded in another page, the consumer should observe this
            // event and decide what to do when an estimate is published.
            if (this.estimatePublished.observers.length > 0) {
              this.estimatePublished.emit(newInvoiceId);
            } else {
              this.router.navigateByUrl(`/quick-entry/invoice/${newInvoiceId}`);
            }
          },
          error => {
            this.snackBar.open('Estimate could not be published to invoice.', 'Error', {
              duration: 2000,
            });
          }
        );
      }
    );
    return;
  }

  finalize() {
    const invoice = this.invoice as Invoice;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        header: 'Send Invoice Email',
        body: `This communication will be sent as an email to ${this.invoice.client.name}.`,
        emailOption: true,
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response === 'true') {
        // Send comm to client
        this.statementService.finalize(this.invoice.id).subscribe(x => {
          invoice.closed = true;
          const snackBarRef = this.snackBar.open('Invoice finalized.', 'Success', {
            duration: 2000,
          });
          snackBarRef.afterDismissed().subscribe(dismissed => {

            this.snackBar.open('Sending client communication..', '', {
              duration: 2000,
            });
            this.statementService.sendInvoiceEmail(this.invoice.client.id, this.invoice.id).subscribe( y => {

              this.snackBar.open('Client communication sent.', 'Success', {
                duration: 2000,
              });
            },
              error => {
                this.snackBar.open('Client communication could not be sent.', 'Error', {
                  duration: 2000,
                });
              });
          });
          this.getInvoice();
        },
          error => {
            this.snackBar.open('Invoice could not be finalized.', 'Error', {
              duration: 2000,
            });
          });
      } else if (response === 'no-email') {
        this.statementService.finalize(this.invoice.id).subscribe(x => {

          invoice.closed = true;
          this.snackBar.open('Invoice finalized.', 'Success', {
            duration: 2000,
          });
          this.getInvoice();
        });
      } else {
        this.snackBar.open('Operation cancelled.', '', {
          duration: 2000,
        });
      }
    });
  }

  addSpace(s) {
    return s.replace(/([A-Z])/g, ' $1').trim();
  }

  update(invoice: InvoiceBase) {
    this.invoice = invoice;
  }

  setInvoiceStatus() {
    const invoice = this.invoice as Invoice;

    this.invoiceService.setInvoiceStatus(this.invoice.id, invoice.invoiceStatus).subscribe(success => {

      this.snackBar.open('Invoice status was updated.', 'Success', {
        duration: 2000,
      });
    }, error => {
      this.snackBar.open('Invoice could not be updated.', 'Error', {
        duration: 2000,
      });
      invoice.invoiceStatus = InvoiceStatusValue.NotReady;
    });
  }

  setKeepRevisions() {
    if (this.keepRevision) {
      this.invoiceService.trackRevisions(this.invoice.id).subscribe(success => {
        this.snackBar.open('Invoice revisions tracked', 'Success', {
          duration: 2000,
        });
      }, error => {
        this.snackBar.open('Invoice could not be tracked.', 'Error', {
          duration: 2000,
        });

        const invoice = this.invoice as Invoice;
        invoice.invoiceStatus = InvoiceStatusValue.NotReady;
      });
    }
  }

  viewLogs() {
    const logPage = new LogPage();
    logPage.itemId = this.invoiceData.id;
    logPage.logType = this.invoiceData.isEstimate ? 'Estimate' : 'LineItem';

    const dialogRef = this.dialog.open(ItemLogDialogComponent, {
      height: 'auto',
      width: 'auto',
      panelClass: 'log-panel',
      data: logPage
    });
  }

  getInvoiceOrEstimateText(allCaps: boolean): string {
    return allCaps
      ? this.invoiceData.isEstimate ? 'ESTIMATE' : 'INVOICE'
      : this.invoiceData.isEstimate ? 'Estimate' : 'Invoice';
  }
}
