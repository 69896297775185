import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { EmailViewModel } from '../models/email.interface';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from 'src/app/helpers/offline-detection';
import { SyncService } from 'src/app/shared/offline/sync-service';
import { dbContext } from 'src/app/shared/offline/offline-db.service';
import { ConfigLoaderService } from 'src/app/config-loader.service';
import {of} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MedicalNoteTemplateService extends BaseService {
  emails: EmailViewModel[];
  constructor(
    http: HttpClient,
    connectionService: ConnectionService,
    configService: ConfigLoaderService,
    public syncService: SyncService) {
    super(http, connectionService, configService);
  }

  public getTemplates() {
    if (this.emails) {
      return of(this.emails);
    }

    const route = `${this.apiUrl}/MedicalNoteTemplate`;

    return this.http.get<EmailViewModel[]>(route , this.headers()).pipe(map(response => {
      this.emails = response;
      return response;
    }));
  }


    public createEmail(email: EmailViewModel ): Observable<any> {

        const route = `${this.apiUrl}/MedicalNoteTemplate`;
        const body = JSON.stringify(email);

        return this.http.post<any>(route, body , this.headers()).pipe(map(response => {
            return response;
        }));
    }


  public getMedicalNoteTemplate(patientId: string, templateId: string): Observable<EmailViewModel> {


    const route = `${this.apiUrl}/MedicalNoteTemplate/${templateId}/Patient/${patientId}`;

    return this.http.get<EmailViewModel>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
