import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Client } from 'src/app/client-management/models/client.interface';
import { ClientService } from 'src/app/client-management/services/client.service';
import { debounceTime } from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {SearchService} from '../search-service';

@Component({
  selector: 'app-search-clients',
  templateUrl: './search-clients.component.html',
  styleUrls: ['./search-clients.component.scss']
})
export class SearchClientsComponent implements OnInit, OnChanges {

  @Input() client: Client;
  @Output() selectedClient = new EventEmitter<Client>();
  @Input() disabled: boolean;
  clientSearch = new FormControl();
  clientResults: Client[] = [];
  filteredOptions: Observable<Client[]>;
  subscriptions: Subscription;
  constructor(
    public clientService: ClientService,
    private searchService: SearchService
  ) { }

  ngOnInit() {
    if (this.client) {
      this.clientSearch = new FormControl(this.client.name);
    } else {
      this.client = new Client();
    }

    if (this.disabled) {
      this.clientSearch.disable();
    }

    this.subscriptions = this.clientSearch.valueChanges.pipe(debounceTime(500)).subscribe(value => this.searchService.searchClients(value));
    this.filteredOptions = this.searchService.searchClientsResultObservable;
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.client.id !== '') {
      this.clientSearch.setValue(this.client.name);
      if (this.disabled) {
        this.clientSearch.disable();
      }
    } else {
      this.clientSearch.setValue('');
    }
  }

  selectClient(client: Client) {

    this.client = client as Client;

    this.selectedClient.emit(this.client);

    this.clientSearch.setValue(this.client.name);
  }

}
