import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {RequestService} from '../../requests-results/services/request.service';
import {
  RefillsDialogData,
  Request,
  RequestPageModel,
  RequestType
} from '../../requests-results/models/request.interface';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RequestDialogComponent} from '../../requests-results/requests/request-dialog/request.dialog';
import {
  ServiceResultsDialogComponent
} from '../../requests-results/requests/service-requests/service-results-dialog/service-results.dialog';
import {
  LabResultsDialogComponent
} from '../../requests-results/requests/lab-requests/lab-results-dialog/lab-results.dialog';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation.dialog';
import {MatDialog} from '@angular/material/dialog';
import {LineItem, LineItemRequest} from '../../line-item-entry/models/line-item.interface';
import {RefillsDialogComponent} from '../invoices/invoice/line-item-form/refills-dialog/refills.dialog';
import {AccountService} from '../../configuration-management/services/account.service';
import {merge} from 'rxjs';
import {map} from 'rxjs/operators';
import {ClientViewDialog} from '../search/client-view-dialog/client-view.dialog';
import {PatientViewDialog} from '../search/patient-view-dialog/patient-view.dialog';
import {
  CommunicationResultsDialogComponent
} from '../../requests-results/requests/communication-requests/communication-results-dialog/communication-results.dialog';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-requests-results-table',
  templateUrl: './requests-results-table.html',
  styleUrls: ['./requests-results-table.scss']
})
// tslint:disable-next-line:component-class-suffix
export class RequestsResultsTable implements OnInit {

  @Input() isRequest = true;
  @Input() requestType: RequestType;
  @Input() branchIds: string[] = [];
  @Input() clientId: string;
  @Input() patientId: string;
  @Input() assigneeId: string;
  @Input() date: Date;
  @Output() dateChange = new EventEmitter();
  @Input() status: string;
  @Input() medicationStatus: string;
  @Input() preference: string;
  @Input() requestCommunicationStatus: string;
  @Input() labId: string;
  @Input() serviceId: string;
  @Input() medicationId: string;

  data = new RequestPageModel();
  selection = new SelectionModel<Request>(true, []);
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  isLoadingResults = true;
  isRateLimitReached = false;
  toggleFilters = false;
  previousRefillData: LineItemRequest;
  requestTypes = RequestType;

  columnsToDisplay: string[];

  constructor(
    public requestService: RequestService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public accountService: AccountService
  ) {
  }

  ngOnInit() {
    this.data = new RequestPageModel();
    this.data.requests = new Array<Request>();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges() {

    if (Object.keys(this.data).length === 0) {
      this.data = new RequestPageModel();
    }
    if (this.isRequest) {
      this.data.page.status = 'All';
    } else {
      this.data.page.status = 'Complete';
    }

    this.data.page.branchIds = this.branchIds;
    this.data.page.clientId = this.clientId;
    this.data.page.patientId = this.patientId;
    this.data.page.requestorId = this.assigneeId;
    this.data.page.date = this.date;
    this.dateChange.emit(this.data.page.date);
    this.data.page.status = this.status ? this.status : this.data.page.status;
    this.data.page.medicationStatus = this.medicationStatus;
    this.data.page.preference = this.preference;
    this.data.page.requestCommunicationStatus = this.requestCommunicationStatus;
    this.data.page.labId = this.labId;
    this.data.page.serviceId = this.serviceId;
    this.data.page.medicationId = this.medicationId;

    this.getData();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    merge(this.paginator.page)
      .pipe(map(i => {
        this.data.page.pageNumber = this.paginator.pageIndex;
        this.data.page.size = this.paginator.pageSize;
        this.getData();
        return this.paginator.pageIndex;
      })).subscribe(x => {
    });
  }

  getRequests() {
    this.data.page.requestType = this.requestType !== RequestType.general ? this.requestType : '';
    this.requestService.getRequests(this.data.page).subscribe(res => {
      this.setData(res);
    });
  }

  getResults() {
    this.data.page.requestType = this.requestType !== RequestType.general ? this.requestType : '';
    this.requestService.getResults(this.data.page).subscribe(res => {
      this.setData(res);
    });
  }

  getData() {
    if (this.isRequest) {
      if (this.requestType === this.requestTypes.communication) {
        this.columnsToDisplay =
          ['select', 'requestType', 'testType', 'testStatus', 'patient', 'orderDate',
            'assignees', 'notes', 'actions'];
      } else {
        this.columnsToDisplay =
          ['requestType', 'testType', 'testStatus', 'patient', 'orderDate',
            'assignees', 'notes', 'actions'];
      }
      this.getRequests();
    } else {
      this.columnsToDisplay =
        ['requestType', 'completed', 'orderDate', 'preference', 'requestCommunicationStatus',
          'testStatus', 'testType', 'patient', 'fulfiller',
          'assignees', 'notes', 'actions'];
      this.getResults();
    }
  }

  setData(results) {
    this.isLoadingResults = false;
    this.isRateLimitReached = false;
    if (Object.keys(results).length !== 0) {
      this.data = results;
      this.paginator.pageIndex = this.data.page.pageNumber;
      this.paginator.pageSize = this.data.page.size;
      this.paginator.length = this.data.page.totalElements;
    }
  }

  changeRequestStatus(request: Request) {
    this.requestService.changeLabRequestStatus(request.id, request.requestCommunicationStatus).subscribe(res => {
        this.snackBar.open('Request status updated', 'Success', {
          duration: 2000,
        });
      },
      error => {
        this.snackBar.open('Request status could not be updated', 'Error', {
          duration: 2000,
        });
      });
  }

  enterResults(request: Request) {
    if (request.requestType === 'Lab') {
      this.enterLabResults(request);
    } else if (request.requestType === 'Service'
      || request.requestType === 'HealthCertificate'
      || request.requestType === 'CareOfRemains') {
      this.enterServiceResults(request);
    } else if (request.requestType === 'Communication') {
      this.enterCommunicationResults(request);
    }
  }

  enterServiceResults(request: Request) {
    const dialogRef = this.dialog.open(ServiceResultsDialogComponent, {
      width: '1000px',
      data: request
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnChanges();
    });
  }

  enterCommunicationResults(request: Request) {
    const dialogRef = this.dialog.open(CommunicationResultsDialogComponent, {
      width: '1000px',
      height: '800px',
      data: request
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnChanges();
    });
  }

  enterLabResults(request: Request) {
    const dialogRef = this.dialog.open(LabResultsDialogComponent, {
      width: '600px',
      data: request
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnChanges();
      }
    });
  }

  completeRequest(request: Request) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: {header: 'Approve Request', body: `You are about to complete a request for ${request.clientName}`}
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.requestService.completeRequest(request.id).subscribe(res => {
            request.requestActionStatus = 'Completed';
            request.requestStatus = 'Complete';

            this.snackBar.open('Request was completed', 'Success', {
              duration: 2000,
            });
          });
        }
      },
      error => {
        this.snackBar.open('Request was not completed', 'Error', {
          duration: 2000,
        });
      });
  }

  viewAdditionalInfo(request: Request) {
    const dialogRef = this.dialog.open(RequestDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: request
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }

  setDroppedOff(request: Request) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        header: 'Drop Off Request',
        body: `You are about to complete a request for ${request.clientName} and set the status to dropped off.`
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.requestService.completeRefillRequest(request).subscribe(res => {
            request.requestActionStatus = 'Completed';
            request.requestStatus = 'Complete';

            this.snackBar.open('Request was completed', 'Success', {
              duration: 2000,
            });
          });
        } else if (request.refillStatus === 'DroppedOff') {
          request.refillDate = this.previousRefillData.refillDate;
          request.refillStatus = this.previousRefillData.refillStatus;
          request.refillStatusDate = this.previousRefillData.refillStatusDate;
          request.refillStatusUserId = this.previousRefillData.refillStatusUserId;
          request.singleRequestNote = this.previousRefillData.singleRequestNote;
        }
      },
      error => {
        this.snackBar.open('Request was not completed', 'Error', {
          duration: 2000,
        });
      });
  }

  setPickedUp(request: Request) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        header: 'Picked Up Request',
        body: `You are about to complete a request for ${request.clientName} and set to picked up.`
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.requestService.completeRefillRequest(request).subscribe(res => {
            request.requestActionStatus = 'Completed';
            request.requestStatus = 'Complete';

            this.snackBar.open('Request was completed', 'Success', {
              duration: 2000,
            });
          });
        } else if (request.refillStatus === 'PickedUp') {
          request.refillDate = this.previousRefillData.refillDate;
          request.refillStatus = this.previousRefillData.refillStatus;
          request.refillStatusDate = this.previousRefillData.refillStatusDate;
          request.refillStatusUserId = this.previousRefillData.refillStatusUserId;
        }
      },
      error => {
        this.snackBar.open('Request was not completed', 'Error', {
          duration: 2000,
        });
      });
  }

  editRefill(request: Request, isEditable: boolean) {
    const lineItem = request.lineItem;
    if (lineItem !== null && (lineItem.lineItemRequest === null || lineItem.lineItemRequest === undefined)) {
      lineItem.lineItemRequest = new LineItemRequest();
    }

    lineItem.lineItemRequest.refillDate = request.refillDate;
    lineItem.lineItemRequest.refillStatus = request.refillStatus;
    lineItem.lineItemRequest.refillStatusDate = request.refillStatusDate;
    lineItem.lineItemRequest.refillStatusUserId = request.refillStatusUserId;
    lineItem.lineItemRequest.singleRequestNote = request.singleRequestNote;
    lineItem.lineItemRequest.multiRequestNotes = request.multiRequestNotes;

    const dialogData = new RefillsDialogData();
    dialogData.IsEditable = isEditable;
    dialogData.LineItem = lineItem;

    const dialogRef = this.dialog.open(RefillsDialogComponent, {
      disableClose: true,
      height: 'auto',
      width: 'auto',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(
      data => {
        const lineItemInput = data as LineItem;
        if (lineItemInput !== null && lineItemInput !== undefined) {
          this.previousRefillData = new LineItemRequest();
          this.previousRefillData.refillDate = request.refillDate;
          this.previousRefillData.refillStatus = request.refillStatus;
          this.previousRefillData.refillStatusDate = request.refillStatusDate;
          this.previousRefillData.refillStatusUserId = request.refillStatusUserId;
          this.previousRefillData.singleRequestNote = request.singleRequestNote;
          this.previousRefillData.multiRequestNotes = request.multiRequestNotes;

          request.refillDate = lineItemInput.lineItemRequest.refillDate;
          request.refillStatus = lineItemInput.lineItemRequest.refillStatus;
          request.refillStatusDate = lineItemInput.lineItemRequest.refillStatusDate;
          request.refillStatusUserId = lineItemInput.lineItemRequest.refillStatusUserId;
          request.singleRequestNote = lineItemInput.lineItemRequest.singleRequestNote;
          request.multiRequestNotes = lineItemInput.lineItemRequest.multiRequestNotes;

          if (request.refillStatus === 'PickedUp') {
            this.setPickedUp(request);
          } else if (request.refillStatus === 'DroppedOff') {
            this.setDroppedOff(request);
          } else {
            this.requestService.updateRefillRequest(request, request.id).subscribe(
              res => {
                this.snackBar.open('Request status updated', 'Success', {
                  duration: 2000,
                });
              },
              error => {
                this.snackBar.open('Request status could not be updated', 'Error', {
                  duration: 2000,
                });
              }
            );
          }
        }
      }
    );
  }

  // Button Conditionals

  editPickupConditions(request) {
    return request.requestType === this.requestTypes.medications &&
      request.refillStatusUserId !== undefined &&
      request.refillStatusUserId !== null &&
      (this.accountService.isSystemAdmin ||
        request.refillStatus !== 'PickedUp');
  }

  viewPickupConditions(request) {
    return !this.accountService.isSystemAdmin &&
      (request.requestType === this.requestTypes.medications &&
        request.refillStatusUserId !== undefined &&
        request.refillStatusUserId !== null &&
        request.refillStatus === 'PickedUp');
  }

  enterResultsConditions(request) {
    return !request.isCompleted &&
      ((request.lineItem && request.requestType !== this.requestTypes.medications) ||
        request.requestType === this.requestTypes.communication);
  }

  viewResultsConditions(request) {
    return request.isCompleted &&
      ((request.lineItem && request.requestType !== this.requestTypes.medications) ||
        request.requestType === this.requestTypes.communication);
  }

  viewConditions(request) {
    return !Object.values(RequestType).includes(request.requestType);
  }

  routeClient(clientId: string) {
    const dialogRef = this.dialog.open(ClientViewDialog, {
      width: '100vw',
      height: '99vh',
      data: clientId
    });
  }

  routePatient(patientId: string) {
    const dialogRef = this.dialog.open(PatientViewDialog, {
      width: '100vw',
      height: '99vh',
      data: patientId
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.requests.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.data.requests.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  getAssigneesDisplay(request: Request): string {
    return Request.getAssigneesDisplay(request);
  }

  sendGenericEmailForCommunicationRequests() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        header: 'Communication request followup',
        body: `You are about to send a follow up email in response to ${this.selection.selected.length} requests.`
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.requestService.genericEmailForCommunicationRequest(this.selection.selected.map(_ => _.id)).subscribe(res => {
            this.snackBar.open('Emails sent', 'Dismiss', {duration: 2000});
          },
          error => {
            this.snackBar.open('Emails not sent', 'Dismiss', {duration: 2000});
          });
      }
    });
  }

  protected readonly RequestType = RequestType;
}
