import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from '../../../client-management/models/client.interface';
import { Router } from '@angular/router';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { PatientViewDialog } from '../patient-view-dialog/patient-view.dialog';
import {Subscription} from "rxjs";
import {ClientService} from "../../../client-management/services/client.service";

@Component({
  selector: 'app-client-view-dialog',
  templateUrl: './client-view.dialog.html',
  styleUrls: ['./client-view.dialog.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ClientViewDialog implements OnInit, OnDestroy {

  client: Client;
  subscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ClientViewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    public router: Router,
    public dialog: MatDialog,
    public clientService: ClientService
  ) { }


  ngOnInit() {
    this.subscription = this.clientService.getClientDialogue(this.data).subscribe(res => {
      this.client = res;
      this.client.patients = this.client.patients.sort((a, b) => (a.patientStatus > b.patientStatus ? 1 : -1));
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  route() {
    this.router.navigateByUrl(`/client-management/client-record/${this.data}`);
    this.dialogRef.close();
  }

  viewPatient(patient: Patient) {

    const dialogRef = this.dialog.open(PatientViewDialog, {
      width: '100vw',
      height: '99vh',
      data: patient.id
  });
  }
  onNoClick() {
    this.dialogRef.close();
  }
}
