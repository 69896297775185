import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Request } from '../../models/request.interface';
import { RequestService } from '../../services/request.service';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Client } from 'src/app/client-management/models/client.interface';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { LineItem } from '../../../line-item-entry/models/line-item.interface';
import { AccountService } from '../../../configuration-management/services/account.service';
import { UserAccount } from 'src/app/configuration-management/models/user.interface';
import { InvoiceService } from '../../../line-item-entry/services/invoice.service';
import { Invoice } from 'src/app/line-item-entry/models/invoice.interface';
import { BranchService } from '../../../configuration-management/services/branch.service';
import { Branch } from 'src/app/configuration-management/models/branch.interface';

@Component({
    selector: 'app-request-dialog',
    templateUrl: './request.dialog.html',
    styleUrls: ['./request.dialog.scss']
})
export class RequestDialogComponent implements OnInit {


    requestForm = new FormGroup({
        assignedUserId: new FormControl(''),
        dateOfProcedure: new FormControl(''),
        sendTo: new FormControl(''),
        pickUpNextDay: new FormControl(false),
        invoiceId: new FormControl(''),
        requestType: new FormControl(''),
        clientId: new FormControl(null),
        clientName: new FormControl(null),
        patientId: new FormControl(null),
        patientName: new FormControl(null),
        labId: new FormControl(null),
        labName: new FormControl(null),
        medicationsSupplies: new FormArray([]),
        branchId: new FormControl(null)
    });

    requestType = '';
    selectedClient = new Client();
    selectedItem = new LineItem();
    selectedPatient = new Patient();
    users: UserAccount[];
    clientInvoices: Invoice[] = [];
    branches: Branch[] = [];
    constructor(

        public dialogRef: MatDialogRef<RequestDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Request,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public requestService: RequestService,
        public accountService: AccountService,
        public invoiceService: InvoiceService,
        public branchService: BranchService
    ) {

    }

    ngOnInit() {
        this.getUsers();
        this.getBranches();

        if (!this.data) {
            this.requestForm.controls.assignedUserId.setValue(this.accountService.currentUserValue.id);
        }
    }

    getBranches() {

        this.branchService.index().subscribe(x => {
          this.branches = x;
        });
      }

    get formArray(): FormArray { return this.requestForm.get('medicationsSupplies') as FormArray; }

    get requestUserId(): string { return this.requestForm.controls.assignedUserId.value; }
    getUsers() {
        this.accountService.getUserAccountsList().subscribe(res => {
            this.users = res.accounts;

            if (this.data !== null) {
                this.requestType = this.data.requestType;

                const medSupplies = new FormArray([]);
                if (this.data.medicationsSupplies) {

                    this.data.medicationsSupplies.forEach(medArrayItem => {

                        const medication = new FormGroup({
                            medicationSupplyId: new FormControl(medArrayItem.medicationSupplyId),
                            quantity: new FormControl(medArrayItem.quantity),
                            note: new FormControl(medArrayItem.note),
                            id: new FormControl(medArrayItem.id),
                            name: new FormControl(medArrayItem.name),
                        });
                        medication.controls.note.disable();
                        medication.controls.quantity.disable();
                        medSupplies.push(medication);
                    });
                }
                let labId = '';
                let labName = '';
                if (this.data.lineItem) {
                    labId = this.data.lineItem.itemId;
                    labName = this.data.lineItem.name;
                }

                this.requestForm = new FormGroup({
                    assignedUserId: new FormControl(this.data.assignedUserId),
                    dateOfProcedure: new FormControl(this.data.dateOfProcedure),
                    sendTo: new FormControl(this.data.sendTo),
                    pickUpNextDay: new FormControl(this.data.pickUpNextDay),
                    invoiceId: new FormControl(''),
                    requestType: new FormControl(this.data.requestType),
                    clientId: new FormControl(this.data.clientId),
                    clientName: new FormControl(this.data.clientName),
                    patientId: new FormControl(this.data.patientId),
                    patientName: new FormControl(this.data.patientName),
                    labId: new FormControl(labId),
                    labName: new FormControl(labName),
                    medicationsSupplies: medSupplies
                });
                const patient = new Patient();
                patient.name = this.data.patientName;
                patient.id = this.data.patientId;
                patient.clientId = this.data.clientId;
                patient.clientName = this.data.clientName;
                if (patient.id) {
                    this.setPatient(patient);
                }
            }
        });
    }

    getInvoices() {
        this.invoiceService.getPatientInvoices(this.selectedClient.id, this.selectedPatient.id).subscribe(
            response => {
                this.clientInvoices = response;
            }
        );
    }

    // Labs

    checkSelectedInvoice(invoiceId: string) {
        if (this.requestForm.controls.invoiceId.value == null) {
            return '';
        }

        if (this.requestForm.controls.invoiceId.value === invoiceId) {
            return 'selected-chip';
        }

        return '';
    }

    setEmptyInvoice() {
        this.requestForm.controls.invoiceId.setValue(null);
    }

    selectInvoice(invoiceId: string) {

        this.requestForm.controls.invoiceId.setValue(invoiceId);
    }


    // Set Patient & Client
    setClient(event: Client) {
        this.selectedClient = new Client();
        this.selectedPatient = new Patient();
        setTimeout(() => {
            this.selectedClient = event;
            this.requestForm.controls.clientId.setValue(this.selectedClient.id);
            this.modelChanged();
        }, 100);
    }

    setPatient(event: Patient) {
        this.selectedPatient = event;
        this.selectedClient = { id: event.clientId, name: event.clientName } as Client;
        this.requestForm.controls.clientId.setValue(this.selectedClient.id);
        this.requestForm.controls.patientId.setValue(this.selectedPatient.id);
        this.getInvoices();
        this.modelChanged();
    }

    modelChanged() {
        let medArray = this.requestForm.controls.medicationsSupplies as FormArray;

        if (this.requestForm.controls.requestType.value === 'Medications' && medArray.controls.length === 0) {

            let medication = new FormGroup({
                medicationSupplyId: new FormControl(''),
                quantity: new FormControl(0),
                note: new FormControl(''),
                id: new FormControl(''),
                name: new FormControl(null),
            });
            medArray.push(medication);
        } else if (this.requestForm.controls.requestType.value !== 'Medications') {

            medArray.clear();
        }

    }

    addMedication() {
        let medArray = this.requestForm.get('medicationsSupplies') as FormArray;

        let medication = new FormGroup({
            medicationSupplyId: new FormControl(''),
            quantity: new FormControl(0),
            note: new FormControl(''),
            id: new FormControl(''),
            name: new FormControl(null),
        });
        medArray.push(medication);
    }

    setMedication(item: LineItem, medication: FormGroup) {
        medication.controls.medicationSupplyId.setValue(item.itemId);
        this.modelChanged();
    }

    setLab(item: LineItem) {
        this.requestForm.controls.labId.setValue(item.itemId);
        this.modelChanged();
    }

    removeMedication(index: number) {
        const medArray = this.requestForm.controls.medicationsSupplies as FormArray;

        medArray.removeAt(index);

        this.modelChanged();
    }

    submit() {
        this.modelChanged();
        this.requestService.makeRequest(this.requestForm.value).subscribe(res => {

            this.snackBar.open('Request has been made', 'Success', {
                duration: 2000,
            });
            this.dialogRef.close(res);
        },
            error => {
                this.snackBar.open('Request could not be created', 'Error', {
                    duration: 2000,
                });
            });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    cancell() {
        this.requestService.cancellRequest(this.data.id).subscribe(res => {

            this.snackBar.open('Request has been cancelled', 'Success', {
                duration: 2000,
            });
            this.dialogRef.close('reload');
        },
            error => {
                this.snackBar.open('Request could not be cancelled', 'Error', {
                    duration: 2000,
                });
            });
    }

    approveRequest() {



        this.requestService.approveRequest(this.data.id).subscribe(res => {
            this.data.requestActionStatus = 'Approved';

            this.snackBar.open('Request was approved', 'Success', {
                duration: 2000,
            });
            this.onNoClick();
        });


    }

    completeRequest() {



        this.requestService.completeRequest(this.data.id).subscribe(res => {
            this.data.requestActionStatus = 'Completed';
            this.data.requestStatus = 'Complete';

            this.snackBar.open('Request was completed', 'Success', {
                duration: 2000,
            });
            this.dialogRef.close(this.data);
        });




    }

    pickedUpRequest() {


        this.requestService.pickedUpRequest(this.data.id).subscribe(res => {
            this.data.pickedUp = true;

            this.snackBar.open('Request was picked up', 'Success', {
                duration: 2000,
            });
            this.dialogRef.close(this.data);
        },
            error => {
                this.snackBar.open('Request was not picked up', 'Error', {
                    duration: 2000,
                });
            });
    }
}
