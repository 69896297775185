import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/helpers/base.service';
import { Observable } from 'rxjs';
import { Estimate } from '../models/invoice.interface';
import { map } from 'rxjs/operators';
import { LineItemSubmission } from '../models/line-item.interface';


@Injectable({
  providedIn: 'root'
})
export class EstimateService extends BaseService {
  public create(model: LineItemSubmission): Observable<Estimate> {
    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Estimate/Create`;

    return this.http.post<Estimate>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: LineItemSubmission, estimateId: string): Observable<Estimate> {
    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Estimate/${estimateId}/Update`;

    return this.http.post<Estimate>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public get(estimateId: string): Observable<Estimate> {
    const route = `${this.apiUrl}/Estimate/${estimateId}`;

    return this.http.get<Estimate>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public publish(model: LineItemSubmission, estimateId: string): Observable<string> {
    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Estimate/${estimateId}/Publish`;

    return this.http.post<string>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public delete(estimateId: string): Observable<null> {
    const route = `${this.apiUrl}/Estimate/${estimateId}/Delete`;
    return this.http.delete<null>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public deleteLineItem(lineItemId: string): Observable<null> {
    const route = `${this.apiUrl}/Estimate/DeleteLineItem?id=${lineItemId}`;
    return this.http.delete<null>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public deleteItems(lineItemIds: string[]): Observable<any> {

    const route = `${this.apiUrl}/Estimate/RemoveLineItems`;

    return this.http.post<any>(route, lineItemIds, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public sendEmail(estimateId: string) {
    const route = `${this.apiUrl}/Estimate/${estimateId}/SendEmail`;

    return this.http.get(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public mergeEstimates(firstEstimateId: string, secondEstimateId: string): Observable<null> {
    const route = `${this.apiUrl}/Estimate/Merge`;
    const body = {
      firstEstimateId,
      secondEstimateId
    };

    return this.http.post<null>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
