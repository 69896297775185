import { Treatment } from 'src/app/whiteboard/models/treatment.interface';

export class PatientVitalGroup {
    whiteboardTreatment = new Treatment();
    date: Date;
    patientId: string;
    id: string;
    patientVitals: PatientVital[];
    vitalGroupId: string;
}


export class PatientVital {
    vitalId: string;
    value: string;
    id: string;
    units: string;
}

export class VitalUnit {
  static kilograms = new VitalUnit('Kilograms', 'Kgs');
  static pounds = new VitalUnit('Pounds', 'Lbs');

  static convertPoundToKilogram = 0.453592;
  static convertKilogramToPound = 2.20462;

  name: string;
  abbreviation: string;

  constructor(name: string, abbreviation: string) {
    this.name = name;
    this.abbreviation = abbreviation;
  }

  static get all(): VitalUnit[] {
    return [
      this.kilograms,
      this.pounds
    ];
  }

  static fromString(value: string) {
    return VitalUnit.all.find(u => u.name === value);
  }
}
