import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
  RequestNote,
  RequestWithNotes,
  SingleOrMultiRequestNotes
} from '../../requests-results/models/request.interface';
import {RequestService} from '../../requests-results/services/request.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation.dialog';
import {MatDialog} from '@angular/material/dialog';
import {AccountService} from '../../configuration-management/services/account.service';

@Component({
  selector: 'app-internal-notes-editor',
  templateUrl: './internal-notes-editor.component.html',
  styleUrls: ['./internal-notes-editor.component.scss']
})
export class InternalNotesEditorComponent implements OnInit {
  resultsForm: FormGroup;
  noteFields: AbstractControl;

  // Determines whether the UI should submit notes to the API using the single or multi format.
  useMultiSubmission: boolean;

  // Determines whether more than one note is allowed.
  allowMultipleNotes: boolean;

  @Input() internalNotesLabel = 'Internal Notes:';
  @Input() appendToInternalNotesLabel = 'Append to Internal Notes:';
  @Input() request: RequestWithNotes;

  // TODO: Remove `string` from union in Stage 3 of the RequestNotes migration
  @Output() noteChange = new EventEmitter<SingleOrMultiRequestNotes>();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public requestService: RequestService,
    public accountService: AccountService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  notesChanged(): void {
    const newValue = this.useMultiSubmission
      ? this.request.multiRequestNotes : this.request.singleRequestNote;

    this.noteChange.emit(newValue);
    this.buildForm();
  }

  buildForm(): void {
    this.useMultiSubmission = this.request.multiRequestNotes != null;
    this.allowMultipleNotes = this.useMultiSubmission && this.request.requestType === 'Communication';

    // Rebuild the form group
    if (this.allowMultipleNotes) {
      this.noteFields = this.formBuilder.array(
        this.request.multiRequestNotes.map(note => note.text)
      );

      // Ensure all existing note boxes are disabled
      this.noteFields.disable();

      this.resultsForm = this.formBuilder.group({
        notes: this.noteFields,
        newNote: [''],
      });
    } else {
      const text = this.useMultiSubmission
        ? this.request.multiRequestNotes[0].text
        : this.request.singleRequestNote;

      this.resultsForm = new FormGroup({
        note: new FormControl(text),
      });
    }
  }

  isValidId(id: string): boolean {
    return id != null && id !== '' && id !== '00000000-0000-0000-0000-000000000000';
  }

  canEditNote(noteIndex: number): boolean {
    const note = this.request.multiRequestNotes[noteIndex];
    return this.accountService.isSystemAdmin && this.isValidId(note.id);
  }

  updateSingleNote() {
    const singleNoteText = this.resultsForm.controls.note.value;
    const requestId = this.request.id;

    if (!this.isValidId(requestId)) {
      this.internalUpdateSingleNote(singleNoteText);
    } else {
      this.requestService.updateSingleRequestNote(requestId, singleNoteText).subscribe(res => {
          this.internalUpdateSingleNote(singleNoteText);

          this.snackBar.open('Request note has been updated', 'Success', {
            duration: 2000,
          });
        },
        error => {
          this.snackBar.open('Request note could not be updated', 'Error', {
            duration: 2000,
          });
        });
    }
  }

  private internalUpdateSingleNote(text: string) {
    if (this.useMultiSubmission) {
      this.request.multiRequestNotes[0].text = text;
    }
    this.request.singleRequestNote = text;
    this.notesChanged();
  }

  addNote(): void {
    const newNoteText = this.resultsForm.controls.newNote.value;

    // Allow users to edit notes that haven't been pushed to the server yet
    if (!this.isValidId(this.request.id)) {
      const newNote = new RequestNote();
      newNote.text = newNoteText;
      newNote.authorId = this.accountService.currentUserValue.id;
      newNote.authorFullName = this.accountService.currentUserValue.firstName + ' '
        + this.accountService.currentUserValue.lastName;
      newNote.dateCreated = newNote.dateModified = new Date(Date.now()).toISOString();

      this.request.multiRequestNotes.splice(0, 0, newNote);
      this.notesChanged();
      return;
    }

    this.requestService.addRequestNote(this.request.id, newNoteText).subscribe(newNote => {
        this.request.multiRequestNotes.splice(0, 0, newNote);
        this.notesChanged();

        this.snackBar.open('Request note has been added', 'Success', {
          duration: 2000,
        });
      },
      error => {
        this.snackBar.open('Request note could not be added', 'Error', {
          duration: 2000,
        });
      });
  }

  updateMultiNote(noteIndex: number) {
    // The user has to click this button twice:
    // first to start editing, then to save their changes

    const noteControl = this.getNoteControls()[noteIndex];
    if (noteControl.disabled) {
      noteControl.enable();
      return;
    }

    noteControl.disable();

    const noteId = this.request.multiRequestNotes[noteIndex].id;
    const newNoteText = noteControl.value;

    // Allow users to edit notes that haven't been pushed to the server yet
    if (!this.isValidId(noteId)) {
      this.request.multiRequestNotes[noteIndex].text = newNoteText;
      this.notesChanged();
      return;
    }

    this.requestService.updateMultiRequestNote(noteId, newNoteText).subscribe(updatedNote => {
        this.request.multiRequestNotes[noteIndex].text = updatedNote.text;
        this.notesChanged();

        this.snackBar.open('Request note has been updated', 'Success', {
          duration: 2000,
        });
      },
      error => {
        this.snackBar.open('Request note could not be updated', 'Error', {
          duration: 2000,
        });
      });
  }

  deleteNote(noteIndex: number) {
    const note = this.request.multiRequestNotes[noteIndex];

    // Confirm before deletion
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        header: 'Delete Note',
        body: `This internal note by ${note.authorFullName} on ${this.formatDate(note.createdDate)} will be deleted.`,
      }
    });

    // Allow users to edit notes that haven't been pushed to the server yet
    if (!this.isValidId(this.request.id)) {
      this.request.multiRequestNotes.splice(noteIndex, 1);
      this.notesChanged();
      return;
    }

    dialogRef.afterClosed().subscribe(deleteConfirmed => {
      if (deleteConfirmed) {
        this.requestService.deleteRequestNote(note.id).subscribe(_ => {
            this.request.multiRequestNotes.splice(noteIndex, 1);
            this.notesChanged();

            this.snackBar.open('Request note was deleted', 'Success', {
              duration: 2000,
            });
          },
          error => {
            this.snackBar.open('Request note could not be deleted', 'Error', {
              duration: 2000,
            });
          });
      } else {
        this.snackBar.open('Operation cancelled.', '', {
          duration: 2000,
        });
      }
    });
  }

  getNoteControls(): AbstractControl[] {
    return (this.resultsForm.controls.notes as FormArray).controls;
  }

  formatDate(date: Date): string {
    if (date === undefined) {
      return '';
    }
    return date.toLocaleString(undefined, { timeZone: 'America/Los_Angeles' });
  }

  getNoteHeader(noteOrIndex: RequestNote | number): string {
    const note = typeof noteOrIndex === 'number'
      ? this.request.multiRequestNotes[noteOrIndex]
      : noteOrIndex as RequestNote;

    const dateString = this.formatDate(note.createdDate);
    return `${note.authorFullName} at ${dateString} PT`;
  }

  getNoteAsPlaintext(index: number): string {
    const note = this.request.multiRequestNotes[index];
    return `${this.getNoteHeader(note)}: ${note.text}`;
  }
}
