import {FormArray, FormControl, FormGroup} from '@angular/forms';

function isArrayIndexProperty(property: string): boolean {
  return /^\d+$/.test(property);
}

export function getNestedFormControl(form: FormGroup, property: string): FormControl {
  let propertyAccessArray = property.split(/\[|\]\.?|\./)
    .filter((p) => p); // filter empty strings


  // set property names to camelCase
  propertyAccessArray = propertyAccessArray.map((prop) => {
    // array indexs i.e. ints should not be modified
    if (isArrayIndexProperty(prop)) {
      return prop;
    }
    return prop[0].toLowerCase() + prop.substring(1);
  });

  let control = form.get(propertyAccessArray[0]);
  propertyAccessArray.slice(1).forEach((prop) => {
    if (isArrayIndexProperty(prop)) {
      control = (control as FormArray).controls[Number.parseInt(prop, 10)];
    } else {
      control = control.get(prop);
    }
  });

  return control as FormControl;
}
