import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { DiscountSummary } from '../models/discounts.interface';
import {of} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class DiscountsService extends BaseService {

  discounts: DiscountSummary[];
  public index(): Observable<DiscountSummary[]> {

    if (this.discounts) {
      return of(this.discounts);
    }

    const route = `${this.apiUrl}/Discount`;
    return this.http.get<DiscountSummary[]>(route, this.headers()).pipe(map(response => {
      this.discounts = response;
      return response;
    }));
  }

  public create(model: any): Observable<any> {

    const route = `${this.apiUrl}/Discount/Create`;
    const body = JSON.stringify(model);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: any): Observable<any> {

    const route = `${this.apiUrl}/Discount/Update`;
    const body = JSON.stringify(model);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

}
