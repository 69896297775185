import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityWithLineItemsReference, EntityWithLineItemsType } from '../../../line-item-entry/models/invoice.interface';

@Component({
  selector: 'app-view-invoice-dialog',
  templateUrl: './view-invoice.dialog.html',
  styleUrls: ['./view-invoice.dialog.scss']
})
export class ViewInvoiceDialogComponent implements OnInit {
  emailOption: boolean;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewInvoiceDialogComponent, InvoiceDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: EntityWithLineItemsReference
  ) { }


  ngOnInit() {

  }

  onNoClick() {
    this.dialogRef.close();
  }

  onEstimatePublished(invoiceId: string) {
    this.dialogRef.close({refresh: true});

    const invoiceData = new EntityWithLineItemsReference();
    invoiceData.id = invoiceId;
    invoiceData.type = EntityWithLineItemsType.Invoice;

    return this.dialog.open<ViewInvoiceDialogComponent, EntityWithLineItemsReference>(ViewInvoiceDialogComponent, {
      disableClose: false,
      height: '90vh',
      width: '95vw',
      data: invoiceData,
      panelClass: 'invoice-panel'
    });
  }
}

export class InvoiceDialogResult {
  refresh = false;
}
