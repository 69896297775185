import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { ClientSummaryListViewModel, ClientSummarySync, Page, Client } from '../../client-management/models/client.interface';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { IndexDetails } from 'ngx-indexed-db';
import { from, Subscription, timer } from 'rxjs';
import { LineItem, LineItemSubmission, Drafts } from '../../line-item-entry/models/line-item.interface';
import { dbContext } from './offline-db.service';
import { LocationSummary } from 'src/app/configuration-management/models/locations.interface';
import { UserAccount } from '../../configuration-management/models/user.interface';
import { DiscountSummary } from '../../configuration-management/models/discounts.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from 'src/app/helpers/offline-detection';
import { Patient } from '../../patient-management/models/patient.interface';
import { EmailViewModel, ClientEmailDraft } from '../../configuration-management/models/email.interface';
import { interval } from 'rxjs';
import { now } from 'moment';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import { ConfigLoaderService } from 'src/app/config-loader.service';

export class SyncRequestBody {
    syncLineItems: number;
    syncInventoryLocations: number;
    syncDoctors: number;
    syncDiscounts: number;
    syncClientsAndPatientSummary: number;
    syncPatients: number;
    syncMedicalNoteTemplates: number;
    lastSyncClientNumber: number;
    lastSyncLineItems: string;
    lastSyncInventoryLocations: string;
    lastSyncDoctors: string;
    lastSyncDiscounts: string;
    lastSyncClientsAndPatientSummary: string;
    lastSyncPatients: string;
    lastSyncMedicalNoteTemplates: string;
    lastSyncPatientNumber: number;
}

export class SyncResponse {
    syncLineItems: LineItem[];
    syncInventoryLocations: LocationSummary[];
    syncDoctors: UserAccount[];
    syncDiscounts: DiscountSummary[];
    syncClientsAndPatientSummary: Client[];
    syncPatients: Patient[];
    syncMedicalNoteTemplates: EmailViewModel[];
    clientNumberLastUpdated: number;
    lastRecordsReturned: string;
    lineItemsLastUpdated: string;
    clientPatientsLastUpdated: string;
    inventoryLocationsLastUpdated: string;
    doctorsLastUpdated: string;
    discountsLastUpdated: string;
    patientsLastUpdated: string;
    patientNumberLastUpdated: number;
    medicalTemplatesLastUpdated: string;
}

@Injectable({
    providedIn: 'root'
})
export class SyncService extends BaseService {

    clients: ClientSummaryListViewModel;
    client: Client;
    isSynch = false;
    clientCount = 0;
    finishedSync = false;
    syncInterval: NodeJS.Timer;
    constructor(
        public snackBar: MatSnackBar,
        http: HttpClient,
        configService: ConfigLoaderService,
        connectionService: ConnectionService) {
        super(http, connectionService, configService);
    }

    createDb() {
    }

    startSync() {
        this.syncAll();
    }

    syncAll() {
        this.syncInterval = setInterval(() => {
            this.syncRequest();
        }, 20000);
        // this.syncRequest();
    }

    async syncRequest() {

        // console.log("Start Sync");
        const today = new Date();

        const tablesCleared:string = localStorage.getItem('tables-cleared-2024-05');
        if (tablesCleared == null) {
            dbContext.emailTemplates.clear();
            localStorage.setItem('tables-cleared-2024-05', 'true');
        }

        const syncAll = new SyncRequestBody();
        syncAll.syncLineItems = await dbContext.lineItems.count();
        syncAll.syncInventoryLocations = await dbContext.inventoryLocations.count();
        syncAll.syncDoctors = await dbContext.doctors.count();
        syncAll.syncDiscounts = await dbContext.discounts.count();
        syncAll.syncClientsAndPatientSummary = await dbContext.clientSearch.count();
        syncAll.syncPatients = await dbContext.patients.count();
        syncAll.syncMedicalNoteTemplates = await dbContext.emailTemplates.count();
        syncAll.lastSyncClientNumber = parseInt(localStorage.getItem('client-number-last-updated'));
        syncAll.lastSyncLineItems = localStorage.getItem('line-items-last-updated');
        syncAll.lastSyncInventoryLocations = localStorage.getItem('inventory-locations-last-updated');
        syncAll.lastSyncDoctors = localStorage.getItem('doctors-last-updated');
        syncAll.lastSyncDiscounts = localStorage.getItem('discounts-last-updated');
        syncAll.lastSyncClientsAndPatientSummary = localStorage.getItem('clients-patients-last-updated');
        syncAll.lastSyncPatients = localStorage.getItem('patients-last-updated');
        syncAll.lastSyncPatientNumber = parseInt(localStorage.getItem('patients-last-updated-patient-number'));
        syncAll.lastSyncMedicalNoteTemplates = localStorage.getItem('medical-templates-last-updated');

        // console.log("Start Syncing");
        let lastRecordsReturned = true;
        let body = JSON.stringify(syncAll);

        this.isSynch = true;

        // console.log("Call Sync Service");
        const route = `${this.apiUrl}/sync`;
        return this.http.post<SyncResponse>(route, body, this.headers()).pipe(map(response => {
            this.isSynch = false;
            return response;
        })).subscribe(response => {

            // console.log("Call to server successful, loading data to offline database.");

            localStorage.setItem('patients-last-updated', response.patientsLastUpdated);
            localStorage.setItem('patients-last-updated-patient-number', response.patientNumberLastUpdated.toString());
            dbContext.bulkAddPatients(response.syncPatients).then(x => {
                if (response.syncPatients.length > 0) {
                    // console.log(response.syncPatients.length + ' patients synced');
                }
            });

            localStorage.setItem('discounts-last-updated', response.discountsLastUpdated);
            dbContext.bulkAddDiscounts(response.syncDiscounts).then(x => {
                if (response.syncDiscounts.length > 0) {
                    // console.log(response.syncDiscounts.length + ' discounts synced');
                }
            });

            localStorage.setItem('doctors-last-updated', response.doctorsLastUpdated);
            dbContext.bulkAddDoctors(response.syncDoctors).then(x => {
                if (response.syncDoctors.length > 0) {
                    // console.log(response.syncDoctors.length + ' doctors synced');
                }
            });

            localStorage.setItem('line-items-last-updated', response.lineItemsLastUpdated);
            dbContext.bulkAddLineItems(response.syncLineItems).then(x => {
                if (response.syncLineItems.length > 0) {
                    // console.log(response.syncLineItems.length + ' line items synced');
                }
            });

            localStorage.setItem('inventory-locations-last-updated', response.inventoryLocationsLastUpdated);
            dbContext.bulkAddInventoryLocations(response.syncInventoryLocations).then(x => {
                if (response.syncInventoryLocations.length > 0) {
                    // console.log(response.syncInventoryLocations.length + ' locations synced');
                }
            });

            localStorage.setItem('clients-patients-last-updated', response.clientPatientsLastUpdated);
            localStorage.setItem('client-number-last-updated', response.clientNumberLastUpdated.toString());
            dbContext.bulkAddClientsAndPatients(response.syncClientsAndPatientSummary).then(x => {
                if (response.syncClientsAndPatientSummary.length > 0) {
                    // console.log(response.syncClientsAndPatientSummary.length + ' clients, patients and statements synced');
                }
            });

            localStorage.setItem('medical-templates-last-updated', response.medicalTemplatesLastUpdated);
            dbContext.bulkAddEmailTemplates(response.syncMedicalNoteTemplates).then(x => {
                if (response.syncMedicalNoteTemplates.length > 0) {
                    // console.log(response.syncMedicalNoteTemplates.length + ' email templates synced');
                }
            });

            localStorage.setItem('last-sync', new Date().toLocaleString());

            if(response.lastRecordsReturned)
            {
                clearInterval(this.syncInterval);
                // console.log('Data has been fully synced');
                this.snackBar.open('Finished syncing, up to date!', 'Sync', {
                    duration: 2000,
                });
                this.finishedSync=true;
                this.isSynch = false;
            }
            else
            {
                this.snackBar.open('Sync cycle completed, updating local database', 'Sync', {
                    duration: 2000,
                 });
            }
        });
    }
}
