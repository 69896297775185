import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Payment, Statement, ClientFinancialSummary } from '../models/statement.interface';
import { LineItem } from 'src/app/line-item-entry/models/line-item.interface';

@Injectable({
  providedIn: 'root',
})
export class StatementService extends BaseService {

  public getStatementByClient(clientId: string): Observable<Statement> {


    const route = `${this.apiUrl}/Statement/GetByClient/${clientId}`;

    return this.http.get<Statement>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getFinancialSummaryClient(clientId: string): Observable<ClientFinancialSummary> {


    const route = `${this.apiUrl}/Statement/GetFinancialSummaryClient/${clientId}`;

    return this.http.get<ClientFinancialSummary>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public sendEndOfMonthStatementEmails(): any {


    const route = `${this.apiUrl}/Statement/SendEndOfMonthStatementEmails`;

    return this.http.get(route, this.fileHeaders()).pipe(map(response => {
      return new Blob([response], { type: 'application/pdf' });
    }));
  }

  public sendEndOfMonthStatementEmail(clientId: string, statementId: string) {


    const route = `${this.apiUrl}/Statement/SendEndOfMonthStatementEmail/${clientId}/statement/${statementId}`;

    return this.http.get(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public sendInvoiceEmail(clientId: string, invoiceId: string) {


    const route = `${this.apiUrl}/Statement/${clientId}/SendInvoiceEmail/${invoiceId}`;

    return this.http.get(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getActiveStatementClients(): Observable<any> {


    const route = `${this.apiUrl}/Statement/GetActiveStatementClients`;

    return this.http.get<any>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getLastPayment(clientId: string): Observable<any> {


    const route = `${this.apiUrl}/Statement/GetLastPayment/${clientId}`;

    return this.http.get<any>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public pay(payment: Payment): Observable<string> {

    const route = `${this.apiUrl}/Statement/Pay`;
    const body = JSON.stringify(payment);

    return this.http.post<string>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
  public getPayment(paymentId: string): Observable<Payment> {

    const route = `${this.apiUrl}/Statement/GetPayment/${paymentId}`;

    return this.http.get<Payment>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updatePayment(payment: Payment, paymentId: string): Observable<string> {

    const route = `${this.apiUrl}/Statement/UpdatePayment/${paymentId}`;
    const body = JSON.stringify(payment);

    return this.http.post<string>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public deletePayment(paymentId: string): Observable<string> {

    const route = `${this.apiUrl}/Statement/DeletePayment/${paymentId}`;

    return this.http.post<string>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public finalize(invoiceId: string): Observable<any> {


    const route = `${this.apiUrl}/Statement/Finalize/${invoiceId}`;

    return this.http.post<any>(route, this.headers()).pipe(map(response => {
      return response;
    }));

  }

  public updateClosedInvoice(invoiceId: string, newList: LineItem[]): Observable<any> {


    const route = `${this.apiUrl}/Statement/UpdateClosedInvoice/${invoiceId}`;
    const body = JSON.stringify({
      invoiceId: invoiceId,
      newLineItems: newList
    });

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
