import {Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VitalService } from '../../configuration-management/services/vital.service';
import { VitalGroup, Order } from '../../whiteboard/models/order.interface';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import {
  FormGroup,
  FormControl,
  FormArray,
  ValidationErrors,
  AbstractControl,
  ValidatorFn
} from '@angular/forms';
import {PatientVitalGroup, VitalUnit} from './patient-vital-group.model';
import { PatientVitalGroupService } from './patient-vital-group.service';
import moment from 'moment';
import { Treatment } from '../../whiteboard/models/treatment.interface';
import { Whiteboard } from '../../whiteboard/models/whiteboard.interface';

export interface PatientVitalGroupDialogData {
  vitalGroup: VitalGroup;
  patient: Patient;
  patientVitalGroup: PatientVitalGroup;
  order: Order;
  hour: string;
  currentDay: string;
  orderType: string;
  whiteboard: Whiteboard;
  treatment: Treatment;
}

@Component({
  selector: 'app-patient-vital-group-dialog',
  templateUrl: './patient-vital-group.dialog.html',
  styleUrls: ['./patient-vital-group.dialog.scss']
})
export class PatientVitalGroupDialogComponent implements OnInit {
  protected readonly Units = VitalUnit;

  weightVitalId = 'fae9f36c-9f9a-47f2-b0a7-aee1996912c7';

  vitalGroups: VitalGroup[];
  selectedGroup: VitalGroup;
  treatmentNotes: string;
  vitalGroupForm = new FormGroup({
    date: new FormControl(new Date()),
    id: new FormControl(null),
    patientVitals: new FormArray([])
  });

  constructor(
    public dialogRef: MatDialogRef<PatientVitalGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatientVitalGroupDialogData,
    public snackBar: MatSnackBar,
    public vitalService: VitalService,
    public patientVitalGroupService: PatientVitalGroupService

  ) { }

  updateForm() {
    let formArray = this.vitalGroupForm.get('vitals') as FormArray;
    formArray = new FormArray([]);
    this.selectedGroup.vitals.forEach(x => {
      const useWeightValidation = x.id === this.weightVitalId;
      const validators = this.getVitalValidators(useWeightValidation);

      const formGroup = new FormGroup({
        vitalId: new FormControl(x.id),
        value: new FormControl(x.defaultValue, validators.value),
        id: new FormControl(null),
        units: new FormControl(null, validators.units),
      });

      if (useWeightValidation) {
        formGroup.patchValue({units: VitalUnit.kilograms.name});
      }

      formArray.push(formGroup);
    });

    const parsedHour = moment(this.data.hour, ['h:mm A']);
    const administeredTime = moment(this.data.currentDay);
    administeredTime.hour(parsedHour.hour());
    administeredTime.minute(0);
    administeredTime.second(0);
    administeredTime.millisecond(0);
    administeredTime.utcOffset(0);

    if (this.data.hasOwnProperty('order')) {
      this.vitalGroupForm = new FormGroup({
        date: new FormControl({ value: administeredTime.toDate(), disabled: true }),
        vitalGroupId: new FormControl(this.selectedGroup.id),
        patientVitals: formArray
      });

    } else if (this.data.hasOwnProperty('treatment')) {
      this.data.vitalGroup = this.data.treatment.patientVitalGroup as unknown as VitalGroup;
      formArray = new FormArray([]);
      this.data.treatment.patientVitalGroup.patientVitals.forEach(x => {
        const isWeightVital = x.vitalId === this.weightVitalId;
        const isNewVital = x.id == null;
        const hasUnits = x.units != null;

        const useWeightValidation = isWeightVital && (isNewVital || hasUnits);
        const validators = this.getVitalValidators(useWeightValidation);

        const formGroup = new FormGroup({
          vitalId: new FormControl(x.vitalId),
          value: new FormControl(x.value, validators.value),
          id: new FormControl(x.id),
          units: new FormControl(x.units, validators.units),
        });

        formArray.push(formGroup);

      });
      this.vitalGroupForm = new FormGroup({
        date: new FormControl({ value: administeredTime.toDate(), disabled: true }),
        vitalGroupId: new FormControl(this.selectedGroup.id),
        patientVitals: formArray,
        id: new FormControl(this.data.treatment.patientVitalGroup.id)
      });
    } else {
      this.vitalGroupForm = new FormGroup({
        date: new FormControl(new Date()),
        vitalGroupId: new FormControl(this.selectedGroup.id),
        patientVitals: formArray
      });
    }
  }

  ngOnInit() {
    this.getItems();
  }

  getItems() {
    this.vitalService.indexVitalGroup().subscribe(response => {
      this.vitalGroups = response;

      if (this.data.hasOwnProperty('order')) {
        this.selectedGroup = this.vitalGroups.find(x => x.id === this.data.order.vitalGroup.id);
        this.updateForm();
      } else if (this.data.hasOwnProperty('treatment')) {

        this.selectedGroup = this.vitalGroups.find(x => x.id === this.data.treatment.patientVitalGroup.vitalGroupId);
        this.updateForm();
      }
    });
  }

  update() {
    const model = this.vitalGroupForm.value as PatientVitalGroup;
    this.patientVitalGroupService.update(model, this.data.patient.id).subscribe(res => {
      this.data.patientVitalGroup = model;
      this.snackBar.open('Record has been updated', 'Success', {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

  submit() {
    const model = this.vitalGroupForm.value as PatientVitalGroup;

    if (this.data.hasOwnProperty('order')) {
      model.whiteboardTreatment = new Treatment();
      const parsedHour = moment(this.data.hour, ['h:mm A']);
      const administeredTime = moment(this.data.currentDay);
      administeredTime.hour(parsedHour.hour());
      administeredTime.minute(0);
      administeredTime.second(0);
      administeredTime.millisecond(0);
      administeredTime.utcOffset(0);
      model.whiteboardTreatment.administeredTime = administeredTime.toDate();
      model.date = administeredTime.toDate();
      model.whiteboardTreatment.orderType = this.data.orderType;
      model.whiteboardTreatment.whiteboardOrderId = this.data.order.id;
      model.whiteboardTreatment.note = this.treatmentNotes;
      model.whiteboardTreatment.patientVital = null;
      model.whiteboardTreatment.patientLabResult = null;
      model.whiteboardTreatment.whiteboardId = this.data.whiteboard.id;
    }

    this.patientVitalGroupService.create(model, this.data.patient.id).subscribe(res => {
      this.snackBar.open('Record has been created', 'Success', {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  getVitalValidators(useWeightValidation: boolean): {value: ValidatorFn[], units: ValidatorFn[]} {
    return {
      value: useWeightValidation ? [weightValueValidator] : [],
      units: useWeightValidation ? [weightUnitsValidator] : [],
    };
  }

  *getControlErrors(control: AbstractControl): Iterable<string> {
    const controlErrors = control.errors;
    if (controlErrors != null) {
      for (const error of Object.values(controlErrors)) {
        yield error;
      }
    }

    const parentControl = control as (FormGroup | FormArray);
    if (parentControl == null || parentControl.controls == null) {
      return;
    }

    for (const childControl of Object.values(parentControl.controls)) {
      // Yield any errors we find in child controls
      for (const error of this.getControlErrors(childControl)) {
        yield error;
      }
    }
  }
}

function weightValueValidator(control: AbstractControl): ValidationErrors | null {
  const errors: ValidationErrors = {};

  if (control.value == null || control.value === '') {
    errors.emptyValue = 'Weight value cannot be empty.';
    return errors;
  }

  const vitalValue = Number(control.value);
  if (isNaN(vitalValue)) {
    errors.notANumber = 'Weight value must be a number.';
  } else if (vitalValue <= 0) {
    errors.outOfRange = 'Weight value must be positive and non-zero.';
  }

  return errors;
}

function weightUnitsValidator(control: AbstractControl): ValidationErrors | null {
  const errors: ValidationErrors = {};
  const units = control.value;

  if (units == null || units === '') {
    errors.emptyValue = 'Weight units cannot be empty.';
  } else if (units !== 'Kilograms' && units !== 'Pounds') {
    errors.outOfRange = `'${units}' is not a valid unit of weight.`;
  }

  return errors;
}
