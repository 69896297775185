import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { LineItem, LineItemSubmission, Drafts } from '../models/line-item.interface';
import { GridModel, Grid } from '../models/grid.interface';
import { EmailViewModel } from 'src/app/configuration-management/models/email.interface';
import { CreateCommunication } from 'src/app/client-management/models/communication.interface';
import { dbContext } from '../../shared/offline/offline-db.service';
import { Draft } from '../models/invoice-draft.interface';
import { MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from 'src/app/helpers/offline-detection';
import { ClientEmailDraft } from '../../configuration-management/models/email.interface';
import { Invoice } from '../models/invoice.interface';
import { ConfigLoaderService } from 'src/app/config-loader.service';
import { PatientMedicalRecordBindingModel } from '../../patient-management/models/patient.interface';


@Injectable({
  providedIn: 'root',
})
export class LineItemInputService extends BaseService {

  public dateOfWork;
  public doctorCode;
  constructor(
    public snackBar: MatSnackBar,
    http: HttpClient,
    configService: ConfigLoaderService,
    connectionService: ConnectionService) {
    super(http, connectionService, configService);
  }

  public getLineItem(lineItemId: string, itemType: string): Observable<LineItem> {
    const route = `${this.apiUrl}/Invoice/GetItem/${lineItemId}/${itemType}`;

    return this.http.get<LineItem>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public getEventGrid( eventId: string): Observable<Grid> {
    const route = `${this.apiUrl}/Invoice/EventGrid/${eventId}`;

    return this.http.get<Grid>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  // Quick Entry
  public submitLineItems(model: LineItemSubmission): Observable<any> {
    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Invoice`;

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateLineItems(model: LineItemSubmission, invoiceId: string, keepRevisions: boolean): Observable<Invoice> {
    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Invoice/${invoiceId}/UpdateLineItems/${keepRevisions}`;

    return this.http.post<Invoice>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public uploadMedicalRecordFile(patientId: string, medicalRecord: PatientMedicalRecordBindingModel, fileToUpload: File) {

    const route = `${this.apiUrl}/Invoice/${patientId}/UploadFile`;

    const formData: FormData = new FormData();
    if (fileToUpload) {
      formData.append('fileKey', fileToUpload, fileToUpload.name);
    }

    formData.append('communicationForm', JSON.stringify(medicalRecord));

    return this.http.post<any>(route, formData).pipe(map(response => {
      return response;
    }));
  }

  public uploadMedicalFile(patientMedicalRecordId: string, fileToUpload: File): Observable<any> {
    const route = `${this.apiUrl}/Invoice/${patientMedicalRecordId}/UploadFile`;

    let formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);

    return this.http.post<any>(route, formData).pipe(map(response => {
      return response;
    }));
  }

  public getMedicalRecord(medicalRecordId: string): Observable<PatientMedicalRecordBindingModel> {
    const route = `${this.apiUrl}/Invoice/GetMedicalRecordEntry/${medicalRecordId}`;
    return this.http.get<PatientMedicalRecordBindingModel>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateMedicalRecord(medicalRecord: PatientMedicalRecordBindingModel): Observable<any> {
    const route = `${this.apiUrl}/Invoice/UpdateMedicalRecordEntry`;
    const body = JSON.stringify(medicalRecord);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public discardLineItemDraft(): Observable<any> {


    const route = `${this.apiUrl}/Invoice/DiscardLineItemDraft`;
    return this.http.post<any>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public discardMedicalRecordDraft(): Observable<any> {
    const route = `${this.apiUrl}/Invoice/DiscardMedicalRecordEntryDraft`;
    return this.http.post<any>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public discardCommunicationDraft(): Observable<any> {
    const route = `${this.apiUrl}/Invoice/DiscardCommunicationEntryDraft`;

    return this.http.post<any>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public discardDrafts(): Observable<any> {
    const route = `${this.apiUrl}/Invoice/DiscardDrafts`;

    return this.http.post<any>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getOfflineDrafts(){
    return dbContext.drafts.toArray();
  }

  public getDrafts(): Observable<Drafts> {

    const route = `${this.apiUrl}/Invoice/GetDrafts`;

    return this.http.get<Drafts>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  getCommunicationFile(communicationId: string): any {
    const route = `${this.apiUrl}/Invoice/${communicationId}/GetCommunicationFile`;
    return this.http.get(route, this.fileHeaders()).pipe(map(res => {
      return new Blob([res], { type: 'application/pdf' });
    }));
  }

  getMedicalRecordFile(patientMedicalRecordId: string): any {
    const route = `${this.apiUrl}/Invoice/${patientMedicalRecordId}/GetFile`;
    return this.http.get(route, this.fileHeaders()).pipe(map(res => {
      return new Blob([res], { type: 'application/pdf' });
    }));
  }


  public submitCommunication(patientId: string, communication: EmailViewModel): Observable<any> {
    const route = `${this.apiUrl}/Invoice/${patientId}/CreateCommunicationEntry`;

    const body = JSON.stringify(communication);


    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  // Grid
  public submitGridItems(gridModel: GridModel): Observable<any> {



    const body = JSON.stringify(gridModel);
    const route = `${this.apiUrl}/Invoice/Grid`;

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public saveGrid(grid: Grid): Observable<Grid> {


    const body = JSON.stringify(grid);
    const route = `${this.apiUrl}/Invoice/SaveGrid`;

    return this.http.post<Grid>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public discardGrid(grid: Grid): Observable<any> {


    const route = `${this.apiUrl}/Invoice/DiscardGrid/${grid.id}`;

    return this.http.post<any>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getGrids(): Observable<Grid[]> {


    const route = `${this.apiUrl}/Invoice/Grids`;

    return this.http.get<Grid[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public addLineItems(clientId: string, patientId: string, lineItems: LineItem[], invoiceId: string, invoiceStatus: string): Observable<any> {
  // public addLineItems(submission: LineItemSubmission, invoiceId: string): Observable<any> {
    const model = new LineItemSubmission();
    model.clientId = clientId;
    model.patientId = patientId;
    model.lineItems = lineItems;
    model.invoiceStatus = invoiceStatus;

    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Invoice/${invoiceId}/AddLineItems`;

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public removeInvoiceItem(invoiceId: string, lineItemId: string, keepRevisions: boolean): Observable<any> {

    const route = `${this.apiUrl}/Invoice/${invoiceId}/RemoveLineItem/${lineItemId}/${keepRevisions}`;

    return this.http.post<any>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public removeInvoiceItems(invoiceId: string, lineItemIds: string[]): Observable<any> {

    const route = `${this.apiUrl}/Invoice/${invoiceId}/RemoveLineItems`;

    return this.http.post<any>(route, lineItemIds, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public hideInvoiceItem(invoiceId, lineItemId): Observable<any> {

    const route = `${this.apiUrl}/Invoice/${invoiceId}/HideFromInvoice/${lineItemId}`;

    return this.http.post<any>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateInvoiceItem(invoiceId: string, lineItem: LineItem): Observable<any> {
    const body = JSON.stringify(lineItem);
    const route = `${this.apiUrl}/Invoice/${invoiceId}/UpdateLineItem`;

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateClosedInvoice(invoiceId: string, lineItem: LineItem): Observable<any> {
    const body = JSON.stringify(lineItem);
    const route = `${this.apiUrl}/Invoice/${invoiceId}/UpdateClosedInvoice`;

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
